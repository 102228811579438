<template>
	<div class="User">
		
		<div class="Top">
			<div class="Left">
				<el-button type="primary" @click="$Jump('/do/user/user_info/add')">添加用户</el-button>
			</div>
			<div class="Right">
				<span style="margin-left: 10px;">
					<label>账号类型</label>
					<el-select v-model="Search.Identity" size="mini">
						<el-option :value="''" label="全部类型"></el-option>
						<el-option :value="'buyer'" label="买家"></el-option>
						<el-option :value="'seller'" label="卖家"></el-option>
						<el-option :value="'admin'" label="管理员"></el-option>
					</el-select>
				</span>
				<span style="margin-left: 10px;">
					<label>账号状态</label>
					<el-select v-model="Search.Status" size="mini">
						<el-option :value="''" label="全部状态"></el-option>
						<el-option :value="50" label="使用中"></el-option>
						<el-option :value="10" label="禁用"></el-option>
					</el-select>
				</span>

				<span style="margin-left: 10px;">
					<label>账号</label>
					<el-input size="mini" v-model="Search.Account"></el-input>
				</span>
				
				<span style="margin-left: 10px;">
					<label>手机号</label>
					<el-input size="mini" v-model="Search.Mobile"></el-input>
				</span>
				
				<span style="margin-left: 10px;">
					<label>用户Id</label>
					<el-input size="mini" v-model="Search.Id"></el-input>
				</span>
				
				
				<span style="margin-left: 10px;">
					<label style="color: rgba(0,0,0,0);">搜索</label>
					<el-button size="mini" type="primary" @click="StartSearching()">搜索</el-button>
				</span>
			</div>
		</div>
		
		<div class="List">
			
			<div class="Title">
				<div class="C1">账号</div>
				<div class="C2">手机号</div>
				<div class="C3">账户状态</div>
				<div class="C4">权限级别</div>
				<div class="C5">注册时间</div>
				<div class="C6">操作</div>
			</div>
			
			<div class="One" v-for="item in UserList" :key="item.Id">
				<li>
					<div class="C1">{{item.Account}}</div>
					<div class="C2">{{item.Mobile}}</div>
					<div class="C3">{{item.StatusName}}</div>
					<div class="C4">{{item.IdentityName}}</div>
					<div class="C5">{{item.CreatedAt}}</div>
					<div class="C6">
						<el-button size="mini" @click="$Jump('/do/user/user_info/'+item.Id)">详情</el-button>
					</div>
				</li>
			</div>
			
		</div>
		
		<div class="Page">
			<el-pagination
			  background
			  layout="prev, pager, next"
			  :total="Total" :page-size="PageSize" :current-page="Page" @current-change="handleCurrentChange">
			</el-pagination>
		</div>

	</div>
</template>

<script>
	import {Select,Option,Dropdown,DropdownMenu,DropdownItem,Pagination} from 'element-ui'
	export default {
	  name: 'UserList',
	  props: {
	  },
	  data() {
	      return {
			  Search:{
				  Id:'',
				  Identity:'',
				  OaNo:'',
				  Mobile:'',
				  Account:'',
				  Name:'',
			  },
			  UserList:[],
			  Total:0,
			  Page:1,
			  PageSize:15,
			  RootRoleList:[],
	      }
	  },
	  components: {
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem,
		'el-select':Select,
		'el-option':Option,
		'el-pagination':Pagination
	  },
	  created() {
		  this.GetRootList()
	  	this.StartSearching()
	  },
	  methods:{
		  GetRootList(){ //获取顶级
		  		let data = {
		  			Service:'Base',
		  			Class: 'RbacRole',
		  			Action: 'List',
		  			Pid:"",
		  					Page:1,
		  					PageSize:500,
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){this.$message(res.Msg);return;}
		  			this.RootRoleList = res.Data.RbacRoleList
		  			
		  		})
		  		.catch(function (response) {
		  					this.$message('网络请求错误')
		  		})
		  },
		  handleCurrentChange(val){
			 this.GetList(val)
		  },
		  StartSearching(){
			  this.GetList(this.Page)
			  this.GetCount()
		  },
		  GetCount(){
			  let data = {
			  	Service:'User',
			  	Class: 'User',
			  	Action: 'Count',
			  	Identity:this.Search.Identity,
			  	OaNo:this.Search.OaNo,
			  	Mobile:this.Search.Mobile,
			  	Account:this.Search.Account,
			  	Name:this.Search.Name,
				Id:this.Search.Id,
				Status:this.Search.Status,
			  }
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
			  	
			  	if(res.ErrorId != 0){
			  		this.$message(res.Msg)
			  		return
			  	}
			  	this.Total = res.Data.Count
			  	
			  })
			  .catch(function (response) {
			  	this.$message('网络请求错误')
			  })
		  },
		  GetList(_page){
		  		let data = {
		  			Service:'User',
		  			Class: 'User',
		  			Action: 'List',
		  			Page:_page,
		  			PageSize:this.PageSize,
					Identity:this.Search.Identity,
					OaNo:this.Search.OaNo,
					Mobile:this.Search.Mobile,
					Account:this.Search.Account,
					Name:this.Search.Name,
					Id:this.Search.Id,
					Status:this.Search.Status,
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
					this.UserList = res.Data.UserList
					this.Page = res.Data.CurrentPage
		  			
		  		})
		  		.catch(function (response) {
					this.$message('网络请求错误')
		  		})
		  }
	  }
	}
</script>

<style scoped>
.User{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.User .Top{
	display: flex;
	align-items: center;
	border-bottom: 1px solid rgba(0,0,0,0.05);
	padding-bottom: 20px;
}
.User .Top .Left{
	
}
.User .Top .Right{
	flex: 1;
	display: flex;
	color: #999999;
	justify-content: flex-end;
}
.User .Top .Right span{
	margin-left: 10px;
}
.User .Top .Right label{
	display: block;
	font-size: 0.9rem;
	color: rgba(0,0,0,0.4);
}
.User .List{
}
.C1,.C2,.C3,.C4,.C5{
	width: 150px;
}
.C1{
	padding-left: 10px;
}
.C6{
	flex: 1;
	text-align: right;
	padding-right: 10px;
}
.User .List .Title{
	display: flex;
	margin-bottom: 20px;
	color: rgba(0,0,0,0.3);
	padding-bottom: 10px;
	padding-top: 10px;
	border-bottom: 1px solid rgba(0,0,0,0.04);
	font-size: 0.9rem;
}
.User .List .One{
	border-bottom: 1px solid rgba(0,0,0,0.04);
	padding: 10px 0px;
}
.User .List .One:hover{
	background-color: rgba(228,0,0,0.1);
}
.User .List .One li{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
</style>
